import noop from 'lodash/noop';
import React, { createContext, PropsWithChildren, useState } from 'react';

import { Alert, Snackbar } from '@mui/material';

// Create Context Object
export const ToastContext = createContext<{
  show: (config: ToastApi) => void;
  hide: () => void;
}>({ show: noop, hide: noop });

interface ToastApi {
  callback?: () => void;
  autoHide?: boolean;
  autoHideDuration?: number;
  type: 'error' | 'warning' | 'info' | 'success';
  position?: 'bottom-left' | 'top-center' | 'bottom-center';
  message: string;
}

// Create a provider for components to consume and subscribe to changes
export const ToastProvider = ({ children }: PropsWithChildren<{}>) => {
  const [config, setConfig] = useState<ToastApi | null>(null);
  const [open, setOpen] = useState(false);

  const show = (newConf: ToastApi) => {
    setConfig(newConf);
    setOpen(true);
  };

  const handleClose = () => {
    config?.callback?.();
    setOpen(false);
  };

  return (
    <ToastContext.Provider
      value={{
        show,
        hide: handleClose,
      }}
    >
      {children}

      <Snackbar
        anchorOrigin={{
          vertical: config?.position === 'bottom-left' ? 'bottom' : 'top',
          horizontal: config?.position === 'bottom-left' ? 'left' : 'center',
        }}
        open={open}
        autoHideDuration={config?.autoHide !== false ? config?.autoHideDuration ?? 4000 : null}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={config?.type} sx={{ width: '100%' }}>
          {config?.message}
        </Alert>
      </Snackbar>
    </ToastContext.Provider>
  );
};
